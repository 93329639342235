<template>
  <div class='card' :class="{selected: isSelected, solved: isSolved}" @click="handleCardClick">
    <div class="back">&nbsp;</div>
    <div class="front" v-if="cardImagePath !== null">
      <img :src="cardImagePath" :alt="'Card Image ' + matchValue" />
    </div>
    <div class="front" v-else>
      <h2>{{ matchValue }}</h2>
    </div>  </div> 
</template>

<script>
  export default {
    name: 'MemoryGameCard',
    props: {
      selected: { default: false, type: Boolean },
      solved: { default: false, type: Boolean },
      matchValue: { required: true, type: Number},
      index: {required: true, type: Number},
      deckTheme: { required: true, type: [String, null], default: null },
      rowIndex: {required: true, type: Number},
      columnIndex: {required: true, type: Number},
    },
    data() {
      return { 
        isSelected: this.selected,
        isSolved: this.solved,
      }
    },
    computed: {
      cardImagePath() {
        const imageTypes = ['jpg', 'jpeg', 'png', 'gif']; // Add more image types if needed

        for (const type of imageTypes) {
          try{
            const imagePath = require(`@/assets/decks/${this.deckTheme}/${this.matchValue}.${type}`);
            return imagePath;
          }
          catch{
            //pass and move on to next image format
          }
        }
        return null; // Return null if no image found
      }
    },
    methods: {
 /*     imageExists(imagePath) {
        try {
          require(imagePath);
          return true;
        } 
        catch (error) {
          return false;
        }
      },
   */   handleCardClick(event){
        event.preventDefault();
        this.$emit('card-clicked', this);
        console.log(this.cardImagePath);
      },

      match() {
      this.isSolved = true;
    },
      unflip() {
        this.isFlipped = false;
      },
    },
  };
</script>

<style>
.card {
  position: relative;
  min-width: 80px;
  width: 100%;
  aspect-ratio: 4/3;
  cursor: pointer;
  perspective:1000px;
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  color: var(--purple);
}

.front, .back {
  width:100%;
  height:100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-position:50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}
.back {
  transform: rotateY(0deg);
  background-color: #fce43a; /*starlight yellow*/
}
.front {
  transform: rotateY(-180deg);
  background-color: #fff; /*cards should be white if blank*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.front img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: 5%;
}

.card.selected
{
  transform: rotateY(180deg); 
}
.card.solved .front{
  opacity:.5;
  transition:opacity 0.4s linear;
}

</style>

