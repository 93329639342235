function generateShuffledDeck(rows, columns){
  let cardValues = [];
  for(let i = 1; i<=(rows*columns); i++){
    cardValues.push(Math.ceil(i/2));
    console.log(Math.ceil(i/2));
  }

  for(let i = (cardValues.length -1); i>0; i--){
    let randomIndex = Math.floor(Math.random()*(i+1));
    let temp = cardValues[i];
    cardValues[i] = cardValues[randomIndex];
    cardValues[randomIndex] = temp;
  }

  let cards = [];

  for(let row = 1; row <= rows; row++){
    console.log("row: " + row);
    for(let column = 1; column <= columns; column ++){
      console.log("column: " + column);
      let i = (row - 1)*columns + (column) ;
      let newCard = {
        "matchValue": cardValues[i-1], //rows and columns count from 1, cardvalues from 0
        "index": i,
        "selected": false,
        "solved": false,
        "rowIndex": row,
        "columnIndex": column,
      }
      cards.push(newCard);
      console.log(newCard);
    }
  }

  return cards;
}


export default generateShuffledDeck;
