<template>
  <div id="app" class="main-content">
  <!--  <player-client-form v-if="isAdmin === false"></player-client-form>
    <admin-client-form v-else></admin-client-form>
  -->
 <!-- <memory-game :rows="5" :columns="6" :cards="cards"></memory-game> -->
  <router-view></router-view>
  </div>
<!--  <section class="menu-bar">
    <div class="menu-bar-left"><h1 class="app-name">Memory<span id="app-phone-number"></span></h1>
    </div>
    <div class="menu-bar-right"><a href="#settings"><img id="settings-icon" class='lower-right-icon' src="./assets/gear.svg" /></a>
    </div>
  </section>
-->
</template>

<script>
// import io from "socket.io-client";
//  import uniqueId from "lodash.uniqueid";
  //import PlayerClientForm from "./components/PlayerClientForm";
  //import AdminClientForm from "./components/AdminClientForm";
          
 // import MemoryGame from "./components/MemoryGame";
  export default {
    name: 'StarlightGamesClient',
    data() {
      return {
        socket: {},
        gameId: {},
        availableGames: [],
        isAdmin: false,
      };
    },
    components: {
//      PlayerClientForm,
//      AdminClientForm 
//        MemoryGame,
    },
    created() {
 //     this.socket = io('http://localhost:1988');  
    },
  };  
</script>
<style>

html, body {
  margin: 0;
  padding: 0;
}

#app {
  display:flex;
  justify-content:center;
  align-items: center;
  margin:0;
  padding:0;
  height: 100%;
  width: 100%;
}

</style>
