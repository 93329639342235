<template>
  <div class="memory-container">
    <div class="column-index" v-if="showIndexLabels">
      <div class="index-label" v-for="label in columnLabels" :key="label">
        <h2>{{ label }}</h2>
      </div>
    </div>
    <div class="row-index" v-if="showIndexLabels">
      <div class="index-label" v-for="label in rowLabels" :key="label"> 
        <h2>{{ label }}</h2>
      </div>
    </div>
    <div class="gameboard">
      <div class="grid-cell" v-for="card in gameCards" :key="card.index">
        <MemoryGameCard
          :matchValue="card.matchValue"
          :selected="card.selected"
          :solved="card.solved"
          :index="card.index"
          :rowIndex="card.rowIndex"
          :columnIndex="card.columnIndex"
          :deckTheme="deckTheme"
          @card-clicked="handleCardClick"
          class="card"
        ></MemoryGameCard>
      </div>
    </div>
  </div>
</template>

<script>
  import MemoryGameCard from "./MemoryGameCard";
 
  export default {
    name: 'MemoryGame',
    props: {
        rows: { required: true, type: Number }, 
        columns: { required: true, type: Number }, 
        players: [],
        cards: { required: true, type: Array },
        deckTheme: { default: "starlight", type: String },
    },
    data() {
      return { 
        socket: {},
        gameCards: this.cards,
        selectedCards: [],
        columnLabels: Array.from({ length: this.columns }, (_, i) => String.fromCharCode(65 + i)),
        rowLabels: Array.from({ length: this.rows }, (_, i) => (i + 1).toString()),
        showIndexLabels: true,
        cardImages: [],
      }
    },
    components: {
      MemoryGameCard,
    },
    created() {
    },
    mounted() {
    },
    computed: {
      cardWidth() { 
        //for simplicity assume that gameboard can't be more than 80vmin
        let width = (75/this.columns) + "vmin";
        return width;
      },
      displayColumns() {
        let col = this.columns + 1;
        return col;
      },
      displayRows() {
        let rows = this.rows + 1;
        return rows;
      },
    },
    methods: {
      handleCardClick(card) {
        var canClick = false;

        if (this.selectedCards.length < 2
            && !card.isSelected) {
              canClick = true;
        }
        
        if(canClick) {
          this.selectedCards.push(card);
          card.isSelected = true;

          if(this.selectedCards.length === 2) {
            this.checkCards();
          }
        }
      },
          
      checkCards(){
        setTimeout(() => {
          const [card1, card2] = this.selectedCards;
          if (card1.matchValue === card2.matchValue) {
            card1.isSolved = true;
            card2.isSolved = true;
          }
          else {
              card1.isSelected = false;
              card2.isSelected = false;
          }
          this.selectedCards = [];
        }, 2500);
      },
    },
  }
</script> 

<style>
.memory-container{ 
/*  max-width: 90%;
  max-height: 90%;
*/
  width: 800px;
  flex: max-content;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: 1fr repeat(v-bind(displayRows), minmax(min-content, max-content)) 1fr;
  grid-template-columns: 1fr repeat(v-bind(displayColumns), minmax(min-content, max-content)) 1fr;
}

.gameboard,
.column-index, 
.row-index{ 
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 2%;
}

.gameboard{
  grid-template-rows: repeat(v-bind(rows), minmax(min-content, max-content));
  grid-template-columns: repeat(v-bind(columns), minmax(min-content, max-content));
  grid-row-start: 3;
  grid-column-start: 3;
  grid-column-end: span v-bind(columns);
  aspect-ratio: v-bind(displayRows)/vbind(displayColumns);
  width:100%;
  max-height: 100%;
}
.column-index{
  grid-template-rows: 1fr; 
  grid-template-columns: repeat(v-bind(columns), max-content);
  grid-row-start: 2;
  grid-column-start: 3;
  grid-column-end: span v-bind(columns);
}

.row-index{ 
  grid-template-columns: 1fr;
  grid-template-rows: repeat(v-bind(rows), max-content);
  grid-row-start: 3;
  grid-column-start: 2;
  grid-row-end: span v-bind(rows);
}

.index-label, 
.grid-cell{
  position: relative;
  min-width: 80px;
  width: 100%;
  aspect-ratio: 4/3;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
