import { createRouter, createMemoryHistory }from 'vue-router';
import PlayerLandingPage from '@/views/PlayerLandingPage.vue'
import GamePage from '@/views/GamePage.vue'
import LocalGamePage from '@/views/LocalGamePage.vue'


const routes = [
  { path: '/', component: PlayerLandingPage },
  { path: '/game/:roomId', component: GamePage, props: true },
{ path: '/local-game', component: LocalGamePage }
];

const router = createRouter({
  routes,
  history: createMemoryHistory(),

});

export default router;
