<template>
  <div>
    <h1>Memory Game - Room {{ roomId }}</h1>
    <!-- Game board and other components -->
  </div>
</template>

<script>
export default {
  props: {
    roomId: {
      type: String,
      required: true
    }
  },
  // Other component options
}
</script>

