<template>
  <div>
    <memory-game :rows="rows" :columns="columns" :cards="shuffledDeck"></memory-game>
  </div>
</template>

<script>
import generateShuffledDeck from "@/utils/generateShuffledDeck.js";
import MemoryGame from "@/components/MemoryGame.vue";

export default {
  name: "LocalGamePage",
  components: {
    MemoryGame,
  },
  data() {
    return {
      numPairs: 15,
      shuffledDeck: [],
      rows: 5,
      columns: 6,
    };
  },
  created() {
    this.shuffledDeck = generateShuffledDeck(this.rows, this.columns);
  },
};
</script>
