<template>
  <div class="starlight-display-column">
    <h1 class="starlight-heading">Memory Game</h1>
    <div v-if="rooms.length === 0" class="starlight-display-column">
      <p>No active rooms currently available. You can play a 1-player practice game instead.</p>
      <br />
      <router-link class="starlight-button" to="/local-game">Practice</router-link>
    </div>
    <div v-else class="starlight-display-column">
      <form class="starlight-form" @submit.prevent="joinRoom">
        <label>
          <h2 class="starlight-heading">Enter your nickname:</h2>
          <input class="starlight-textinput" type="text" v-model="nickname" required>
        </label>
      </form>
      <hr />
      <div class="starlight-display-column">
        <p>Or you can practice with a single player game</p>
        <router-link class="starlight-button" to="/local-game">Practice</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nickname: "",
      selectedRoom: "",
      rooms: [
        // You can add active rooms here or fetch them from a server
      ],
    };
  },
  methods: {
    joinRoom(roomName) {
      if (roomName) {
        this.selectedRoom = roomName;
      }
      // Here you can add code to join the selected room with the given nickname
      console.log(`Joining room ${this.selectedRoom} as ${this.nickname}`);
    },
  },
};
</script>

<style>
</style>

